<template>
  <div
    class="flex flex-col items-center p-1 duration-75 mobile:w-full"
    :class="isWide ? 'w-1/3' : 'w-1/4'"
    @click="toggleExpand"
  >
    <div
      class="file-container w-full flex flex-row flex-wrap rounded-xl items-center mobile:py-2 mobile:px-2 duration-100 shadow cursor-pointer"
      :class="[
        !isWhite && !currentExpanded ? 'bg-offwhite' : '',
        isWhite && !currentExpanded ? 'bg-white' : '',
        currentExpanded && currentExpanded === file.id
          ? 'text-white bg-green shadow-outline'
          : 'text-darkgrey bg-offwhite hover:bg-darkgreen hover:text-white hover:shadow-outline',
        currentExpanded ? '' : 'h-full'
      ]"
    >
      <div class="flex p-2 justify-center items-center w-full h-full">
        <div class="flex">
          <img class="icon" :src="parseFileTypeIcon()" />
        </div>
        <div class="flex flex-col justify-center w-full h-full">
          <div class="flex flex-row items-center w-full font-semibold">
            <span class="w-full pl-2 break-words">
              {{
                file.description !== "" ? file.description : file.file_name
              }}</span
            >
          </div>
          <div class="flex items-end justify-center">
            <span class="w-full pl-2"> {{ file.uploaded_by }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RequestHandler from "@/assets/mixins/RequestHandler";
import { directive as onClickaway } from "vue-clickaway2";
import FileTypeParser from "@/assets/mixins/FileTypeParser";

export default {
  name: "ProjectFile",
  props: {
    file: {
      type: Object,
      default: () => {
        return {};
      }
    },
    userRole: {
      type: String,
      default: ""
    },
    company: {
      type: String,
      default: ""
    },
    currentExpanded: {
      type: Number,
      default: null
    },
    isWide: {
      type: Boolean,
      default: false
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    draftUuid: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      expanded: false,
      editMode: false,
      editing: {
        description: false,
        group: false
      },
      confirmDeleteModalActive: false
    };
  },
  mixins: [RequestHandler, FileTypeParser],
  methods: {
    toggleExpand() {
      if (this.draftUuid) {
        this.$store.dispatch("fileViewer/openFileModal", {
          file: this.file,
          type: "companyFile",
          company: this.company,
          draft: this.draftUuid
        });
      } else {
        this.$store.dispatch("fileViewer/openFileModal", {
          file: this.file,
          type: "companyFile",
          company: this.company
        });
      }
    },
    parseFileTypeIcon() {
      return this.parseFileType(this.file);
    },
    closeSidebar() {
      if (this.currentExpanded === this.file.id) {
        this.$emit("closeFile");
      }
    }
  },
  directives: {
    onClickaway: onClickaway
  },
  computed: {
    parseDate() {
      return moment(this.file.created_at).format("DD.MM.YYYY");
    },
    parseTime() {
      return moment(this.file.created_at).format("HH:mm:ss");
    },

    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL;
    }
  }
};
</script>

<style lang="scss">
.file-container {
  transition: 0.1s;
  .icon-container {
    @apply h-24 w-24 rounded-md bg-darkgrey flex justify-center items-center;
  }
  .icon {
    @apply h-16 w-16;
  }
}

@media (max-width: 992px) {
  .file-container {
    @apply w-11/12;
  }
}
</style>
